import React, { Component, Fragment } from "react";
//import { Query } from "react-apollo";
//import gql from "graphql-tag";

import Select from "react-select";

import ShopifyProduct from "../../components/Product/ShopifyProduct";
import Loader from "../../components/Loader/Loader";
import ErrorHandler from "../../components/ErrorHandler/ErrorHandler";
import "./Products.css";

class Products extends Component {
  state = {
    isEditing: false,
    shop_products: [],
    shop_product: [],
    products: [],
    product: [],
    selectedOption: null,
    editProduct: null,
    productLoading: false,
    //editLoading: false
    date: new Date()
  };

  componentDidMount() {
    //window.location.href =
    //"https://asphalte-paris-dev.myshopify.com/admin/apps/mcp-shop-control";
    this.loadProducts();
  }

  loadProducts() {
    this.setState({ productLoading: false });
    fetch(process.env.REACT_APP_TUNNEL_URL + "/api", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + this.props.token,
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        if (res.status !== 200) {
          throw new Error("Failed to fetch products.");
        }
        return res.json();
      })
      .then(resData => {
        if (resData.errors) {
          throw new Error("Fetching products failed!");
        }
        let options = resData.productsList.map(product => {
          return { value: product.node.id, label: product.node.title };
        });
        //console.log(options);
        this.setState({
          shop_products: resData.productsList,
          options: options
        });
        //console.log(this.state.shop_products);
      })
      .catch(this.catchError);
  }

  handleChange = selectedOption => {
    this.setState({ selectedOption });
    this.setState({ productLoading: true });
    const productId = selectedOption.value;
    console.log(`Option selected:`, productId);
    // Query to Shopify Datas
    fetch(
      process.env.REACT_APP_TUNNEL_URL +
        "/api/product/?productId=" +
        selectedOption.value,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + this.props.token,
          "Content-Type": "application/json"
        }
      }
    )
      .then(res => {
        if (res.status !== 200) {
          throw new Error("Failed to fetch product datas.");
        }
        return res.json();
      })
      .then(resData => {
        console.log(resData);
        if (resData.errors) {
          throw new Error("Fetching products data failed!");
        }
        this.setState({
          shop_product: resData.productDatas,
          productLoading: false
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({ productLoading: false });
      });
  };

  productChangeHandler = (propertyName, event) => {
    //const { name, value } = event.target;
    console.log(propertyName);
    console.log(this.state.product);
    const product = this.state.product;
    product[propertyName] = event.target.value;
    this.setState({
      product: product
    });
  };

  productSubmitHandler = event => {
    //console.log(this.state.product);
    console.log("PRODUCT SUBMITTED");
    event.preventDefault();
    let graphqlQuery = {
      query: `
          mutation CreateNewProduct($gid: String!, $description: String!, $timer: String!) {
            createProduct(productInput: {gid: $gid, description: $description, timer: $timer}) {
              gid
              description
              timer
              createdAt
            }
          }
        `,
      variables: {
        gid: this.state.product.gid,
        description: this.state.product.description,
        timer: this.state.date
      }
    };
    console.log(this.state.editProduct);
    if (this.state.editProduct) {
      graphqlQuery = {
        query: `
              mutation UpdateExistingProduct($gid: String!, $description: String!, $timer: String!) {
                updateProduct(gid: $gid, productInput: {gid:$gid, description: $description, timer: $timer}) {
                  gid
                  description
                  timer
                  createdAt
                }
              }
            `,
        variables: {
          gid: this.state.product.gid,
          description: this.state.product.description,
          timer: this.state.date
        }
      };
    }
    console.log(graphqlQuery);
    // Update Custom Datas
    return fetch(process.env.REACT_APP_TUNNEL_URL + "/graphql", {
      method: "POST",
      body: JSON.stringify(graphqlQuery),
      headers: {
        Authorization: "Bearer " + this.props.token,
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        return res.json();
      })
      .then(resData => {
        console.log(resData);
      });
  };

  errorHandler = () => {
    this.setState({ error: null });
  };

  catchError = error => {
    this.setState({ error: error });
  };

  render() {
    const { selectedOption } = this.state;

    return (
      <Fragment>
        <ErrorHandler error={this.state.error} onHandle={this.errorHandler} />
        <section className="product__select">
          <div className="dropdown">
            <p>
              Sélectionner un produit parmi les{" "}
              {this.state.shop_products.length} produits:
            </p>
            <Select
              value={selectedOption}
              onChange={this.handleChange}
              options={this.state.options}
            />
          </div>
        </section>
        <section className="product__content">
          {this.state.productLoading && (
            <div style={{ textAlign: "center", marginTop: "2rem" }}>
              <Loader />
            </div>
          )}

          {selectedOption && !this.state.productLoading && (
            <div>
              <ShopifyProduct
                shop_product={this.state.shop_product}
                productLoading={this.state.productLoading}
                selectedOption={this.state.selectedOption}
              />
            </div>
          )}
        </section>
      </Fragment>
    );
  }
}

export default Products;
