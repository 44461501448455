import React from "react";

import "./Loader.css";

const loader = (props) => (
  <div className="btn-loader">
    <div />
    <div />
    <div />
    <div />
  </div>
);

export default loader;
